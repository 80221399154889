import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Modal, ModalFooter, ModalBody } from '../components/Modal';
import {
  DropdownMenu,
  Link,
  Row,
  Col,
  Switch,
  Text,
  Button,
  cssVariables,
  Icon,
  SimpleInput,
  UsedByGraph,
} from '../components';
import { t, formatPercentage } from '../i18n';
import arrowIcon from '../../../assets/images/ad_list_arrow.svg';

const UPDATE_FB_ADTEXT_MUTATION = gql`
  mutation UpdateFbAdtext($id: BigInt!, $name: String) {
    updateFbAdtext(id: $id, name: $name) {
      fbAdtext {
        id
        name
        status
        headline
        description
        format
        creativeSource
      }
    }
  }
`;

const UPDATE_FB_ADTEXT_STATUS_MUTATION = gql`
  mutation UpdateFbAdtext($id: BigInt!, $status: String) {
    updateFbAdtext(id: $id, status: $status) {
      fbAdtext {
        id
        name
        status
        headline
        description
        format
        creativeSource
      }
    }
  }
`;

const DELETE_FB_ADTEXT_MUTATION = gql`
  mutation DeleteFbAdtext($id: BigInt!) {
    deleteFbAdtext(id: $id) {
      fbAdtext {
        id
      }
    }
  }
`;

const FbAdtextTile = ({ item, refetch, setVersion, showItemsMenu, showArrow }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tempName, setTempName] = useState(item.name);
  const [updateFBAdtextMutation] = useMutation(UPDATE_FB_ADTEXT_MUTATION);
  const [deleteFBAdtextMutation] = useMutation(DELETE_FB_ADTEXT_MUTATION);
  const [updateFBAdtextStatus] = useMutation(UPDATE_FB_ADTEXT_STATUS_MUTATION);

  const openEditModal = () => {
    setTempName(item.name);
    setShowEditModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleNameChange = value => {
    setTempName(value);
  };

  const handleSave = async () => {
    try {
      await updateFBAdtextMutation({
        variables: {
          id: item.id,
          name: tempName,
        },
      });
      refetch();
      closeEditModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating item:', error);
    }
  };

  const handleSwitchChange = async value => {
    try {
      await updateFBAdtextStatus({
        variables: {
          id: item.id,
          status: value ? 'running' : 'paused',
        },
      });
      refetch();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating item status:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteFBAdtextMutation({
        variables: {
          id: item.id,
        },
      });
      refetch();
      setVersion(v => v + 1);
      closeDeleteModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error deleting item:', error);
    }
  };

  if (!item) return null;

  let productsAspectRatio = 0;
  if (item?.fbProductSetInfo?.count_at_product_set && item?.fbProductSetInfo?.products_count) {
    productsAspectRatio = formatPercentage(
      item?.fbProductSetInfo?.products_count > 0
        ? (item?.fbProductSetInfo?.count_at_product_set / item?.fbProductSetInfo?.products_count) * 100
        : 0
    );
  }

  return (
    <div style={{ position: 'relative' }} data-test-id="ad-card">
      {showArrow && (
        <img
          src={arrowIcon}
          alt="arrow-icon"
          style={{ display: 'block', position: 'absolute', left: '-26px', top: '37px' }}
        />
      )}
      <Row
        center
        justifyBetween
        style={{
          minHeight: '82px',
          marginTop: 0,
          marginBottom: '8px',
          padding: '12px',
          borderRadius: '8px',
          border: `1px solid ${cssVariables.interfaceOutlineBold}`,
          background: cssVariables.interfaceSurfaceLowest,
        }}
      >
        <Row center>
          <Col shrink style={{ marginRight: '12px' }}>
            <Switch
              pausePlay
              doNotUseInternalState
              value={item.status === 'running'}
              onChange={({ target: { value } }) => handleSwitchChange(value)}
            />
          </Col>
          {item?.previewImageUrl && (
            <Col shrink style={{ marginRight: '12px' }}>
              <img
                src={item?.previewImageUrl}
                style={{ width: '40px', height: '56px', objectFit: 'cover' }}
                alt={item?.name}
              />
            </Col>
          )}
          <Col style={{ marginRight: '12px' }}>
            <Link href={item?.editLink}>{item?.name}</Link>
            <Text
              tag="div"
              style={{ color: cssVariables.textSubtle, marginTop: '4px' }}
              size="sm"
              data-test-id="ad-type"
            >
              {item?.humanizedFormat} / {item?.humanizedCreativeSource}
            </Text>
          </Col>
        </Row>

        <Col shrink>
          <Row center>
            {item.creativeSource === 'manual_upload' && (
              <Col shrink style={{ marginRight: '12px', paddingRight: '32px' }}>
                <Text tag="div" style={{ color: cssVariables.textSubtle }} size="sm" data-test-id="ad-info">
                  {t('fb_advantage_plus.ads_overview_page.static_ad', { default: 'Static ad' })}
                </Text>
              </Col>
            )}
            {item.creativeSource === 'catalog' && (
              <Row center style={{ marginRight: '12px', paddingRight: '32px', width: '193px' }}>
                <Col shrink style={{ marginRight: 0 }}>
                  <UsedByGraph
                    products={item?.fbProductSetInfo?.count_at_product_set}
                    productsTotal={item?.fbProductSetInfo?.products_count}
                  />
                </Col>
                <Col data-test-id="ad-info">
                  <Link icon="product-set" href={item?.fbProductSetInfo?.product_set_url} size="sm">
                    {item?.fbProductSetInfo?.product_set_name}
                  </Link>
                  <Text tag="div" style={{ color: cssVariables.textSubtle, marginTop: '2px' }} size="sm">
                    <span style={{ fontWeight: 'bold' }}>{item?.fbProductSetInfo?.count_at_product_set}</span>{' '}
                    {t('fb_advantage_plus.ads_overview_page.products', { default: 'products' })}{' '}
                    <span style={{ fontWeight: 'bold' }}>({productsAspectRatio})</span>
                  </Text>
                </Col>
              </Row>
            )}
            <Col shrink style={{ marginRight: '12px' }}>
              <Button kind="tertiary" tag="a" onlyIcon icon="edit" href={item.editLink} data-test-id="ad-edit" />
            </Col>
            {showItemsMenu && (
              <Col shrink>
                <DropdownMenu secondary hideOnClick={false} placement="auto-start" data-test-id="ad-dropdown">
                  <div style={{ padding: '8px', marginBottom: '4px' }}>
                    <Switch
                      label={item.status === 'running' ? 'Pause ad' : 'Run ad'}
                      pausePlay
                      doNotUseInternalState
                      value={item.status === 'running'}
                      onChange={e => {
                        handleSwitchChange(e.target.value);
                      }}
                    />
                  </div>
                  <div style={{ marginBottom: '4px' }}>
                    <Link icon="type" onClick={openEditModal}>
                      Rename
                    </Link>
                  </div>
                  <div>
                    <Link danger icon="trash" onClick={openDeleteModal}>
                      Delete
                    </Link>
                  </div>
                </DropdownMenu>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {showEditModal && (
        <Modal
          onClose={closeEditModal}
          size="small"
          heading={
            <Row center>
              <Icon kind="type" size="24px" className="mr-12" color={cssVariables.iconSubtle} />
              Rename ad
            </Row>
          }
        >
          <ModalBody>
            <SimpleInput
              name="name"
              value={tempName}
              onChange={handleNameChange}
              label="Edit name"
              id={item.id}
              showClearButton={false}
              testId="ad-name"
            />
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button primary onClick={handleSave}>
                  Save
                </Button>
              </Col>
              <Col>
                <Button secondary onClick={closeEditModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          onClose={closeDeleteModal}
          size="small"
          heading={
            <Row center>
              <Icon kind="trash" size="24px" className="mr-12" color={cssVariables.statusAttentionDefault} />
              {`Delete ${item.name}`}
            </Row>
          }
        >
          <ModalBody>
            <p>
              Are you sure you want to delete {item.name}? This action cannot be undone. Please confirm if you want
              proceed with deletion.
            </p>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button kind="red" icon="trash" onClick={handleDelete}>
                  Delete
                </Button>
              </Col>
              <Col>
                <Button secondary onClick={closeDeleteModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

FbAdtextTile.propTypes = {
  item: PropTypes.object,
  refetch: PropTypes.func,
  setVersion: PropTypes.func,
};

export default FbAdtextTile;
